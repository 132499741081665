import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";
import MemeDropDown from "./MemeDropDown";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import MemePaginator from "./MemePaginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare as square } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

class Item extends Component {
  constructor(props) {
    super(props);

    this.genItems = this.genItems.bind(this);
    this.paginatorSelect = this.paginatorSelect.bind(this);
    this.displayedItems = this.displayedItems.bind(this);

    this.state = {
      items: [],
      displayedItems: [],
      pagination: { displayItemPage: 1 }
    };
  }

  displayedItems(i) {
    var items = i.slice();
    var start =
      (this.state.pagination.displayItemPage - 1) * this.props.itemsPerPage;
    var end = this.state.pagination.displayItemPage * this.props.itemsPerPage;
    return items.slice(start, end);
  }

  genItems(item) {
    //NEED TO ADD STATE CHANGES ON ONCLICK

    if (item) {
      var btnCSS = { marginRight: 15, marginBottom: 15 };
      return (
        <Card id={"cardID" + item.key}>
          <Card.Img variant="top" src={item.src} />
          <Card.Body>
            <Card.Title>{item.name}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {item.price} {item.pricingUnit}
            </Card.Subtitle>
            <Card.Text>{item.desc}</Card.Text>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <ButtonToolbar>
                {/* <MemeDropDown
                  id={"colorDD" + item.key}
                  itemNum={item.key}
                  title="Color"
                  data={item.colors}
                  type="COLOR"
                  select={params => {
                    var itemNum = params.id;
                    var color = params.value;

                    //Iterate through items array to see if item already exists in state.
                    var i;
                    for (i = 0; i < this.state.items.length; i++) {
                      if (this.state.items[i].itemNum == itemNum) {
                        //entry exists, update
                        var itemArr = this.state.items.slice();
                        itemArr[i].color = color;
                        this.setState({ items: itemArr });
                        return; //exit function, we are done
                      }
                    }

                    //Item doesn't exist, add it.
                    this.setState(prevState => ({
                      items: [
                        ...prevState.items,
                        { itemNum: itemNum, color: color, size: null }
                      ]
                    }));
                  }}
                />
                <MemeDropDown
                  id={"sizeDD" + item.key}
                  itemNum={item.key}
                  title="Size"
                  data={item.sizes}
                  type="SIZE"
                  select={params => {
                    var itemNum = params.id;
                    var size = params.value;

                    //Iterate through items array to see if item already exists in state.
                    var i;
                    for (i = 0; i < this.state.items.length; i++) {
                      if (this.state.items[i].itemNum == itemNum) {
                        //entry exists, update
                        var itemArr = this.state.items.slice();
                        itemArr[i].size = size;
                        this.setState({ items: itemArr });
                        return; //exit function, we are done
                      }
                    }

                    //Item doesn't exist, add it.
                    this.setState(prevState => ({
                      items: [
                        ...prevState.items,
                        { itemNum: itemNum, color: null, size: size }
                      ]
                    }));
                  }}
                />  */}
                <Link to={`/store/product/${item.key}`}>
                  <Button
                    variant="success"
                    style={btnCSS}
                  >
                    View Options <FontAwesomeIcon icon={square} /> Buy it
                  </Button>
                </Link>
              </ButtonToolbar>
            </div>
          </Card.Body>
        </Card>
      );
    } else {
      return null;
    }
  }

  paginatorSelect(val) {
    //Update selected paginator value, then change displayed items.
    this.setState(
      {
        pagination: {
          displayItemPage: val
        }
      },
      () =>
        this.setState({ displayedItems: this.displayedItems(this.props.items) })
    );
  }

  componentWillReceiveProps(nextProps) {
    //Set the items to be displayed once Item component receives new props - the async firestore call
    //is being completed and we need to update them.
    if (nextProps.items.length > 0) {
      var filteredItems = this.displayedItems(nextProps.items);
      this.setState({ displayedItems: filteredItems });
    }
  }

  componentDidMount() {
    //This code is necessary because when the react router changes to the store page,
    //componentWillReceiveProps is not fired.
    if (this.props.items && this.props.items.length > 0) {
      var filteredItems = this.displayedItems(this.props.items);
      this.setState({ displayedItems: filteredItems });
    }
  }

  render() {
    var items = this.props.items;

    var displayedItems = this.state.displayedItems;

    var containerPadding = { padding: 15 };
    return (
      <Container style={containerPadding}>
        <CardColumns>{displayedItems.map(this.genItems)}</CardColumns>
        <MemePaginator
          data={items}
          paginatorSelect={obj => {
            this.paginatorSelect(obj);
          }}
          itemsPerPage={this.props.itemsPerPage}
          currentPage={this.state.pagination.displayItemPage}
        />
      </Container>
    );
  }
}

export default Item;
