import React, { Component } from "react";

class Checkout extends Component{


    render(){
        return(<h1>{JSON.stringify(this.props.cart)}</h1>)
    }

}

export default Checkout