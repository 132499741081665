import firebase from 'firebase'
  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyC-09WQD3RMcco2lKwU0y_AQRduxN49lFE",
    authDomain: "memefactory-b0234.firebaseapp.com",
    databaseURL: "https://memefactory-b0234.firebaseio.com",
    projectId: "memefactory-b0234",
    storageBucket: "",
    messagingSenderId: "949338724937",
    appId: "1:949338724937:web:ea060430f6fad7e2"
  };
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;