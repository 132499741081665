import React, { Component } from "react";
import Item from "./store/Item";
import firebase from "./store/Firestore";
import MemeNav from "./MemeNav";
import TestComponent from "./store/TestComponent";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import ProductDetail from "./store/ProductDetail";
import { css as loaderCss } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import MemeFooter from "./store/MemeFooter";
import cloneDeep from "lodash/cloneDeep";
import Checkout from "./store/Checkout";
import "./store/all.css";
import Feed from "./feed/feed";

var itemsPerPage = 9;

class App extends Component {
  constructor(props) {
    super(props);

    this.getFirestoreItems = this.getFirestoreItems.bind(this);
    this.consolidateCart = this.consolidateCart.bind(this);
    this.addToCart = this.addToCart.bind(this);

    this.state = {
      productsLoading: false,
      items: [],
      cart: [],
      isCartPaneOpen: false,
      handleQuantityChange: false
    };
  }

  //Executed when user hits "add to cart button" from ProductDetail,
  //NOT the form inside of the sidebar
  addToCart(cart, item) {
    return this.consolidateCart(cart, item, null);
  }

  checkCartNumItems(cart) {
    if (cart && cart.length > 0) {
      var total = 0;
      for (var i = 0; i < cart.length; i++) {
        total += cart[i].num;
      }
      console.log("Total= " + total);
      return total;
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    //Prevent re-render if item quantity is being changed
    if (this.state.isCartPaneOpen && nextState.isCartPaneOpen) {
      return false;
    }
    return true;
  }

  consolidateCart(cart, item, num) {
    //JSON schema
    //// {[{obj:{<itemobj>}, num:X},{}...]}

    var cartTemp = cloneDeep(cart);
    for (var i = 0; i < cartTemp.length; i++) {
      if (cartTemp[i].obj.name == item.name) {
        if (!num) {
          //This if statement makes no sense. Cant update num if its null logically.
          cartTemp[i].num++;
        } else {
          cartTemp[i].num = num;
        }
        return cartTemp;
      }
    }

    cartTemp.push({ obj: item, num: 1 });
    return cartTemp;
  }

  onLoading() {
    const override = loaderCss`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    return (
      <div className="sweet-loading" style={{ marginTop: 100 }}>
        <GridLoader
          css={override}
          sizeUnit={"px"}
          size={50}
          color={"#000"}
          loading={this.state.loading}
        />
      </div>
    );
  }

  componentDidMount() {
    this.setState(
      {
        productsLoading: true
      },
      () => {
        this.getFirestoreItems();
      }
    );
  }

  getFirestoreItems() {
    console.log("Getting items from Firestore...");
    const db = firebase.firestore();
    db.collection("testproducts")
      .get()
      .then(snapshot => {
        var itemArr = [];
        snapshot.forEach(doc => {
          itemArr.push(doc.data());
        });
        this.setState({
          productsLoading: false,
          items: itemArr
        });
      })
      .catch(err => {
        console.log("Error getting documents", err);
        this.setState({
          productsLoading: false,
          items: null
        });
      });
  }

  render() {
    var items = this.state.items;
    var loading = this.state.productsLoading;
    if (loading) {
      return this.onLoading();
    } else {
      return (
        <div>
          <Router>
            <MemeNav />
            {/*             <Route
              exact
              path="/"
              component={() => {
                return <Feed/>
              }}
            ></Route> */}
            <Route exact path="/">
              <Redirect to="/store" />
            </Route>
            <Route
              exact
              path="/store"
              component={() => {
                return <Item itemsPerPage={itemsPerPage} items={items} />;
              }}
            ></Route>
            <Route path="/contact" component={TestComponent} />
            <Route
              path="/store/product/:key"
              component={({ match }) => {
                return (
                  <ProductDetail
                    synchronizeCarts={(e, cartItems) =>
                      this.setState({ cart: cartItems })
                    }
                    items={this.state.items}
                    isPaneOpen={this.state.isCartPaneOpen}
                    addToCart={item => {
                      var newCart = this.addToCart(this.state.cart, item);
                      this.setState({
                        cart: newCart,
                        isCartPaneOpen: true
                      });
                    }}
                    onQtyChange={(event, item) => {
                      var num = event.target.value;
                      var newCart = this.consolidateCart(
                        this.state.cart,
                        item,
                        num
                      );
                      this.setState({
                        cart: newCart,
                        isCartPaneOpen: true,
                        handleQuantityChange: true
                      });
                    }}
                    onClosePane={() => {
                      var isCartPaneOpen = false;
                      this.setState({ isCartPaneOpen: false });
                    }}
                    cartItems={this.state.cart}
                    selectedKey={match.params.key}
                  />
                );
              }}
            />
            <Route
              path="store/checkout"
              render={() => <Checkout cart={this.state.cart} />}
            />
            <MemeFooter />
          </Router>
        </div>
      );
    }
  }
}

export default App;
