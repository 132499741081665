import React, { Component } from "react";

class TestComponent extends Component {

    render(){
        var style = {margin: 100}
        return (<div><h1 className={style}>TEST COMPONENT</h1></div>) 
    }


}

export default TestComponent