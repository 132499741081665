import React, { Component } from "react";
import { render } from "react-dom";
import Modal from "react-modal";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import MemeCartItem from "./MemeCartItem";
import Button from "react-bootstrap/Button";
import "./all.css";
import cloneDeep from "lodash/cloneDeep";
import { Route, Link } from "react-router-dom";
import axios from "axios";
import StripeCheckout from "react-stripe-checkout";

class MemeCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartVal: 0,
      cartTemp: this.props.cartItems,
      height: 0
    };
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
    this.bindTotalCartValue();
  }

  currencyFormatter(value) {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });
    return formatter.format(value);
  }

  bindTotalCartValue() {
    var total = 0;
    var cartObj = this.props.cartItems;
    cartObj.forEach(i => (total += i.num * i.obj.price));
    console.log("Cart total: $" + total);
    this.setState({ cartVal: total });
  }

  //TODO: Add CSS to pane to make mobile = 100vh, screen size > 768px should be width= 400px
  render() {
    var paneWidth = "400px";
    var cartObj = this.props.cartItems; //all items in cart

    return (
      <div ref={ref => (this.el = ref)}>
        <SlidingPane
          width={paneWidth}
          className=""
          overlayClassName=""
          isOpen={this.props.isPaneOpen}
          title="Shopping Cart"
          subtitle="Pls buy stuff"
          onRequestClose={() => {
            this.props.onPaneClose(); //Tells the parent that the window has been closed
            //this.setState({isPaneOpen: !this.state.isPaneOpen})
          }}
        >
          {cartObj.map(o => (
            <MemeCartItem
              onQtyChange={(e, i) => {
                e.preventDefault();
                this.props.onQtyChange(e, i);

                //create temporary copy of cart. Update item quantity.
                var cartCopy = cloneDeep(this.props.cartItems);
                var qty = parseInt(e.target.value);
                var cartVal = 0;
                cartCopy.forEach(c => {
                  if (i.key == c.obj.key) {
                    c.num = qty;
                  }
                  cartVal += c.obj.price * c.num;
                });

                this.setState({ cartTemp: cartCopy, cartVal: cartVal });
              }}
              num={o.num}
              item={o.obj}
            />
          ))}
          <div style={{ marginBottom: 142 }} />
          <div
            style={{
              backgroundColor: "#fff",
              bottom: 0,
              position: "fixed",
              width: paneWidth,
              left: 0,
              padding: 16
            }}
          >
            <hr />
            <div className="d-flex justify-content-between">
              <div>
                <strong>Total: </strong>
              </div>
              <div>{this.currencyFormatter(this.state.cartVal)}</div>
            </div>
            <Link
              //to="/checkout" Link is not necessary; Stripe popup used instead
              onClick={e => {
                //Synchronize MemeCart and App's carts.
                var cartItems = this.state.cartTemp;
                this.props.synchronizeCarts(e, cartItems);
              }}
            >
              <StripeCheckout
                amount="4500"
                billingAddress
                description="Thank u"
                image="https://www.rawshorts.com/freeicons/wp-content/uploads/2017/01/green_prodpictdollar_1484336218-1.png"
                locale="auto"
                name="Complete Purchase"
                stripeKey=""
                token={this.onToken}
                zipCode
              >
                <Button
                  style={{ marginTop: 15, width: "100%" }}
                  variant="success"
                >
                  Proceed to Checkout
                </Button>
              </StripeCheckout>
            </Link>
          </div>
        </SlidingPane>
      </div>
    );
  }
}

export default MemeCart;
