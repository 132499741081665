import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import FormControl from "react-bootstrap/FormControl";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
class MemeNav extends Component {

  constructor(props){
    super(props)
  }

  render() {
    return (
      <Navbar bg="light" expand="lg">
        <Link to="/store">
          <Navbar.Brand href="/store">
            <img
              src="/fm_logo.png"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/store">
              <Nav.Link active href="/store">Store</Nav.Link>
            </Link>
            <Link>
              <Nav.Link disabled>Cart</Nav.Link>
            </Link>
            <Link>
              <Nav.Link disabled>Contact</Nav.Link>
            </Link>
          </Nav>
          <Form inline>
            <Button onClick={(event)=>{
              alert("This is a site that demonstrates a modern online store built with React.js. " + 
              "What is being demonstrated is primarily the look, but also the smooth, streamlined transition " +
              "between all components in the web-app (that is, the home page, product detail, shopping card modal, " +
              "followed by the Stripe checkout pane at the end). A final note is that the \"Family Mart\"" +
               " logo is actually not mine and is borrowed solely because it has a nice color scheme." +
               " Again, this site is just for demo purposes!")
            }} variant="primary">Site Info</Button> 
          </Form>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
export default MemeNav;
