import React, { Component } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";

class Feed extends Component {
  constructor(props) {
    super(props);

    this.getPosts = this.getPosts.bind(this);
  }

  //Currently works offline only
  getPosts() {}

  render() {
    var containerPadding = { padding: 15 };
    return (
      <Container style={containerPadding}>
        <CardColumns columnCount={2}>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>POST 1</Card.Title>
              <Card.Text>
                This card has supporting text below as a natural lead-in to
                additional content.{" "}
              </Card.Text>
              <Card.Text>
                <small className="text-muted">Last updated 3 mins ago</small>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>POST 1</Card.Title>
              <Card.Text>
                This card has supporting text below as a natural lead-in to
                additional content.{" "}
              </Card.Text>
              <Card.Text>
                <small className="text-muted">Last updated 3 mins ago</small>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>POST 1</Card.Title>
              <Card.Text>
                This card has supporting text below as a natural lead-in to
                additional content.{" "}
              </Card.Text>
              <Card.Text>
                <small className="text-muted">Last updated 3 mins ago</small>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardColumns>
      </Container>
    );
  }
}

export default Feed;
