import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

class MemeFooter extends Component{

    render(){
        return(
            <Container>
                <Row>
                    <Col>
                    <hr/>
                    <p className="text-muted text-center">Created by Bigsoftware, LLC. (c) 2019</p>
                    </Col>
                </Row>
            </Container>
        )
    }

}

export default MemeFooter