import React, { Component } from "react";
import Pagination from "react-bootstrap/Pagination";

class MemePaginator extends Component {
  constructor(props) {
    super(props);
    this.renderOptions = this.renderOptions.bind(this);
  }

  renderOptions() {
    /*
    Function will display a max of 5 pages (9 items per page)
    before displaying ellipses "skipping numbers" in the 
    pagination bar.

    TODO: Code ellipses criteria
    TODO: Code button functionality
    */

    var numItems = this.props.data.length;
    var itemsPerPage = parseInt(this.props.itemsPerPage);
    //Without using parseInt() there will be arithmetic errors
    var currentPage = parseInt(this.props.currentPage);

    const paginator = [];
    paginator.push(
      <Pagination.First
        onClick={e => {
          e.preventDefault();
          this.props.paginatorSelect(1);
        }}
      />
    );
    paginator.push(
      <Pagination.Prev
        onClick={e => {
          var backVal =
            currentPage == 1 ? 1 : currentPage - 1;
          e.preventDefault();
          this.props.paginatorSelect(backVal);
        }}
      />
    );
    var i;
    for (i = itemsPerPage; i < numItems + itemsPerPage; i += itemsPerPage) {
      var buttonVal = i / itemsPerPage;
      paginator.push(
        <Pagination.Item
          onClick={e => {
            e.preventDefault();
            this.props.paginatorSelect(e.target.text);
          }}
        >
          {buttonVal}
        </Pagination.Item>
      );
    }
    paginator.push(
      <Pagination.Next
        onClick={e => {
          console.log("i = " + i)
          console.log("current page == i/itemsPerPage -1 : "+ currentPage == i / itemsPerPage - 1)
          console.log("current page= " + currentPage);
          console.log("next page val = " + currentPage + 1)
          var nextVal =
            currentPage == i / itemsPerPage - 1
              ? currentPage
              : currentPage + 1;
          e.preventDefault();
          this.props.paginatorSelect(nextVal);
        }}
      />
    );
    paginator.push(
      <Pagination.Last
        onClick={e => {
          var nextVal =
            currentPage == i / itemsPerPage - 1
              ? currentPage
              : i / itemsPerPage - 1;
          e.preventDefault();
          this.props.paginatorSelect(nextVal);
        }}
      />
    );

    return paginator;
  }

  render() {
    var paginatorItems = this.props.data ? this.renderOptions() : null;
    return (
      <div>
        <Pagination className="justify-content-center">
          {paginatorItems}
        </Pagination>
      </div>
    );
  }
}

export default MemePaginator;
