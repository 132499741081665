import React, { Component } from "react";
import { css as loaderCss } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpandArrowsAlt as size,
  faVenusMars,
  faPalette
} from "@fortawesome/free-solid-svg-icons";
import MemeCart from "./MemeCart";

import Card from "react-bootstrap/Card";

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.setItemState = this.setItemState.bind(this);
    this.getProductDetail = this.getProductDetail.bind(this);
    this.state = {
      selectedItem: {},
      loading: true,
      isPaneOpen: false,
      genderVal: null,
      sizeVal: null,
      colorVal: null
    };
  }

  componentDidMount() {
    this.setItemState(this.props.selectedKey);
    window.scrollTo(0, 0);
  }

  setItemState(key) {
    var items = this.props.items;
    if (items) {
      items.forEach(i => {
        console.log(JSON.stringify(i.key) == JSON.stringify(key));
        if (JSON.stringify(i.key) == JSON.stringify(key)) {
          this.setState({ selectedItem: i, loading: false });
        }
      });
    }
  }

  onLoading() {
    const override = loaderCss`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    return (
      <div className="sweet-loading" style={{ marginTop: 100 }}>
        <GridLoader
          css={override}
          sizeUnit={"px"}
          size={50}
          color="#007bff"
          loading={this.state.loading}
        />
      </div>
    );
  }

  getProductDetail() {
    var containerPadding = { padding: 15 };
    var item = this.state.selectedItem;
    return (
      <Container style={containerPadding}>
        <MemeCart
          synchronizeCarts={this.props.synchronizeCarts}
          onQtyChange={this.props.onQtyChange}
          cartItems={this.props.cartItems}
          item={this.state.selectedItem}
          isPaneOpen={this.props.isPaneOpen}
          onPaneClose={this.props.onClosePane}
        />
        <Row>
          <Col>
            <h1>{item.name}</h1>
            <p className="text-muted"> {item.desc}</p>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Image src={item.src} style={{ paddingBottom: 15 }} fluid rounded />
          </Col>
          <Col md={4}>
            <Card bg="light">
              <Card.Header>Please choose the following:</Card.Header>
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={size} /> Size
                </Card.Title>
                <Card.Text>
                  <DropdownButton
                    className="d-flex flex-column"
                    id="dropdown-basic-button"
                    title={this.state.sizeVal==null?"Select Size":this.state.sizeVal}
                  >
                    {item.sizes.map(i => (
                      <Dropdown.Item onSelect={(a,b)=>{
                        this.setState({sizeVal:i});
                      }}>{i}</Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Card.Text>
                <Card.Title>
                  <FontAwesomeIcon icon={faPalette} /> Color
                </Card.Title>
                <Card.Text>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={this.state.colorVal==null?"Select Color":this.state.colorVal}
                    className="d-flex flex-column"
                  >
                    {item.colors.map(i => (
                      <Dropdown.Item onSelect={(a,b)=>{
                        this.setState({colorVal:i});
                      }}>{i}</Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Card.Text>
                <Card.Title>
                  <FontAwesomeIcon icon={faVenusMars} /> Gender
                </Card.Title>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={this.state.genderVal==null?"Select Gender":this.state.genderVal}
                  className="d-flex flex-column"
                >
                  {["Male", "Female"].map(i => (
                    <Dropdown.Item onSelect={(a,b)=>{
                      this.setState({genderVal:i});
                    }}>{i}</Dropdown.Item>
                  ))}
                </DropdownButton>
              </Card.Body>
            </Card>
            <div className="d-flex flex-column" style={{ marginTop: 15 }}>
              <Button
                variant="success"
                onClick={e => {
                  this.props.addToCart(item);
                }}
              >
                {item.price} {item.pricingUnit} - Add to Cart
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    if (this.state.loading == true) {
      return this.onLoading();
    } else {
      var item = this.state.selectedItem;
      console.log(item.name);
      return this.getProductDetail();
    }
  }
}

export default ProductDetail;
