import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";

class MemeCartItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var item = this.props.item;
    console.log(item)
    var cardStyle = {
        marginBottom: 15
    }

    return (
      <Card style={cardStyle}>
        <Card.Body>
          <Row>
            <Col xs={4}>
              <Image width="100%" rounded src={item.src} />
            </Col>
            <Col xs={8}>
              <p>
                <strong>{item.name}</strong>
              </p>
              <Form>
                <Form.Group as={Row} controlId={item.key + "QuantityID"}>
                  <Col xs={5}>
                    <Form.Control onChange={(event)=>{
                        this.props.onQtyChange(event, item)
                        event.preventDefault(); 
                    }} 
                        defaultValue={this.props.num} 
                        size="md" type="number" placeholder="#" />
                  </Col>
                  <Form.Label column xs={"auto"}>
                    @ {item.price + " " + item.pricingUnit}
                  </Form.Label>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default MemeCartItem;
