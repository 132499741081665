import React, { Component } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

class MemeDropDown extends Component {
  constructor(props) {
    super(props);

    this.genDropDownItems = this.genDropDownItems.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = { id: null, value: null, dropdownValue: null};
  }

  genDropDownItems(item) {
    return (
      <Dropdown.Item
      as="button"
        key={item}
        href={this.props.type + item}
        onSelect={(eventKey, e) => {
          e.preventDefault();
          var selection = {
            type: this.props.type,
            value: item,
            id: this.props.itemNum
          };
          this.setState({ id: this.props.id, dropdownValue: item });
          this.props.select(selection); //Pass selection object back to parent (Item)
        }}
      >
        {item}
      </Dropdown.Item>
    );
  }

  handleChange() {}

  render() {
    var ddCSS = { marginRight: 15, marginBottom: 15 };
    var ddItems = this.props.data.map(this.genDropDownItems);

    return (
      <DropdownButton
        style={ddCSS}
        ref={a => (this.dd = a)}
        id={this.props.id}
        title={this.state.dropdownValue == null ? this.props.title : this.state.dropdownValue}
      >
        {ddItems}
      </DropdownButton>
    );
  }
}

export default MemeDropDown;
